.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
}

.navbar-title-a {
    text-decoration: none;
    color: black;
}
 
.navbar-title {
    font-size: 1.5rem;
    color:lightgray;
}

.navbar-links-container {
    display: none;
    justify-content: space-evenly;
    width: max-content;
    align-items: center;
}

.links {
    padding-left: 4%;
    padding-right: 4%;
    text-decoration: none;
    color: black;
    color:lightgray;
}
 
.links:hover {
    color: rgb(100, 99, 99);
}

.hamburger-container {
    width: 3em;
    height: 3em;
    display: flex;
    align-items: center;
}

.hamburger-container:hover {
    cursor: pointer;
}

.hamburger-line {
    width: 3em;
    height: .3em;
    background-color: white;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
}

.hamburger-line::before {
    content: "";
    display: block;
    position: absolute;
    background-color: white;
    width: 3em;
    height: .3em;
    top: 1.1em;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
}

.hamburger-line::after {
    content: "";
    display: block;
    position: absolute;
    background-color: white;
    width: 3em;
    height: .3em;
    top: 2.6em;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
}

.hamburger-line-transformed {
    transition: all 0.2s ease-in-out;
    width: 0;
    height: .3em;
    border-radius: 5px;
}

.hamburger-line-transformed::before {
    content: "";
    display: block;
    position: absolute;
    width: 3em;
    height: .3em;
    background-color: white;
    transform: translateY(1em) rotate(45deg);
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
}

.hamburger-line-transformed::after {
    content: "";
    display: block;
    position: absolute;
    width: 3em;
    height: .3em;
    background-color: white;
    transform: translateY(-.55em) rotate(-45deg);
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
}

@media screen and (min-width: 900px) {
    .hamburger-container {
        display: none;
    }
    .navbar-links-container {
        display: flex;
    }
}