.login {
    background-image: url("../assets/testimage10.png");
    background-size:cover;
    width: 100vw;
    height: 100vh;
}
.login-content {
    width: fit-content;
    margin-top: 10vh;
    margin-left: auto;
    margin-right: auto;
}
 
.login-container {
    display: flex;
    flex-direction: column;
    border: solid 2px black;
    border-radius: 10px;
    background-color: rgb(24, 24, 24);
    color: lightgray;
}
 
.login-form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 20em;
    max-width: 70vw;
    height: 30em;
    min-height: max-content;
    text-align: center;
    color: lightgray;
}

#login-email-input-id {
    border: none;
    height: 2em;
    margin-left: 5%;
    margin-right: 5%;
    background-color: rgb(63, 63, 63);
    color: white;
    border-radius: 2px;
}

#login-pass-input-id {
    border: none;
    height: 2em;
    margin-left: 5%;
    margin-right: 5%;
    background-color: rgb(63, 63, 63);
    color: white;
    border-radius: 2px;
}

.login-link {
    text-decoration: none;
    color: lightgray;
}

.login-question {
    text-decoration: none;
    color: lightgray;
}

#login-submit-id {
    width: max-content;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
    padding-bottom: 3%;
    height: max-content;
    margin-left: auto;
    margin-right: auto;
    border: none;
    border: solid 2px black;
    border-radius: 5px;
}

.login-error-box {
    width: 0;
    overflow: hidden;
}

.login-error-box-show{
    color: white;
    background-color: red;
    width: 60vw;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
}