.calendar {
    background-image: url("../assets/testimage10.png");
    color: white;
}

.rbc-calendar {
    background-color: rgb(24, 24, 24);
}

.calendar-container {
    display: flex;
    width: 100%;
    height: 100vh;
}

.first-day {
    height: 10em;
    width: 100%;
    background-color: lightgray;
    outline: 1px;
    outline-color: red;
    outline-style: solid;
}

.rbc-btn-group:nth-child(3) {
    display: none;
}

.rbc-off-range-bg {
    background-color: rgb(77, 77, 77);
}

.rbc-today {
    background-color: rgb(77, 77, 77);
}

.rbc-btn-group {
    color: white;
    background-color: rgb(77,77,77);
}

.rbc-btn-group > button:nth-child(1){
    color: white;
}

.rbc-btn-group > button:nth-child(1):hover{
    color: black;
}

.rbc-btn-group > button:nth-child(2){
    color: white;
}

.rbc-btn-group > button:nth-child(2):hover{
    color: black;
}

.rbc-btn-group > button:nth-child(3){
    color: white;
}

.rbc-btn-group > button:nth-child(3):hover{
    color: black;
}