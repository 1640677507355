.GetProject {
    background-image: url("../assets/testimage10.png");
}

.getproject-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.getproject-form {
    margin-left: auto;
    margin-right: auto;
    width: 8em;
    height:3.5em;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.getproject-create {
    width: 100%;
    height: 100%;
}

.project-area {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20em, 30em));
    gap: 1em;
    justify-content:space-evenly;
}

.project-cards {
    width: 30em;
    height: 15em;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(24,24,24);
    border-radius: 16px;
    color: lightgray;
    overflow-x: auto;
}

.project-card-top {
    width: 100%;
    text-align: center;
    background-color: rgb(77,77,77);
}

.project-card-button {
    height: 4em;
    width: 10em;
}

.project-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 1em;
    border-radius: 5px;
    border: solid 2px black;
}

.project-title {
    text-align: center;
} 

.project-date {

}

.project-button {
    width: fit-content;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 1%;
}

.create-project-container {
    width: 30em;
    height: 15em;
    display: flex;
    flex-direction: column;
    background-color: gray;
    position: absolute;
    top: 15em;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 16px;
    background-color: rgb(19, 19, 19);
    color: white;
    border: solid 2px black;
}

.create-project-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(77,77,77);
    margin-bottom: 1em;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.create-project-title {
    text-align: center;
    margin-left: 7.6em;
}

.create-project-close {
    margin-right: 2em;
    font-weight: 1000;
    font-size: 2em;
    cursor: pointer;
}

.create-project-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.create-project-input {
    width: 20em;
    height: 2.5em;
    border-radius: 5px;
    background-color: rgb(63, 63, 63);
    color: white;
}

.create-project-button {
    width: 10em;
    height: 4em;
}

@media screen and (max-width: 959px) {
    .project-view-deadlines {
        margin-top: 1em;
    }
}

@media screen and (max-width: 600px) {
    .project-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .project-cards {
        max-width: 90%;
    }

    .create-project-container {
        width: 90%;
    }

    .create-project-top {
        display: flex;
        justify-content: space-evenly;
    }

    .create-project-title {
        margin-left: 0em;
    }
    .create-project-close {
        margin-right: 0em;
    }

    .create-project-input {
        width: 60%;
    }

}