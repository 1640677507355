.viewproject {
    display: flex;
    flex-direction: column;
    background-image: url("../assets/testimage10.png");
    background-size: cover;
    gap: 1em;
}

.project-content-top {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.project-content-top-left {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.project-view-notes {
    width: 30em;
    height: 30em;
    background-color: rgb(24, 24, 24);
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
}

.project-view-notes-title {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: .5em;
    padding-bottom: .5em;
    color:white;
    text-align: center;
    background-color: rgb(77,77,77);
}

.note-items-title {
    color: white;
    margin: .3em;
    cursor: pointer;
    margin-left: 5em;
}

.new-note-date {
    margin-right: 5em;
    color: white;
}

.show-note-delete {
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

.delete-element-container {
    width: 20em;
    text-align: center;
    height: 7em;
    background-color: rgb(77,77,77);
    border-radius: 16px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
}

.delete-element-heading {
    color: white;
}

.delete-element-yes {
    color: white;
    background-color: transparent;
    border: solid 2px black;
    border-radius: 5px;
    margin-right: 1em;
    cursor: pointer;
    width: 5em;
    height: 2em;
}

.delete-element-yes:hover {
    background-color: gray;
}

.delete-element-no {
    color: white;
    background-color: transparent;  
    border: solid 2px black;
    border-radius: 5px;
    margin-left: 1em;
    cursor: pointer;
    width: 5em;
    height: 2em;
}

.delete-element-no:hover {
    background-color: gray;
}

.file-items-title {
    text-decoration: none;
    color: white;
    margin: .3em;
}

.file-items-title:hover {
    color:black;
}

.project-view-files-title {
    color: white;
    background-color: rgb(77,77,77);
    width: 100%;
    text-align: center;
    padding-top: .5em;
    padding-bottom: .5em;
    margin-top: 0;
    margin-bottom: 0;
}

.show-note-container {
    position: absolute;
    display: flex;
    z-index: 1;
    top: 20%;
    left: 0;
    right: 0;
    margin-left: auto; 
    margin-right: auto;
    width: 40em;
    height: 30em;
    flex-direction: column;
    align-items: center;
    background-color: rgb(24, 24, 24);
    border-radius: 16px;
}

.show-note-container-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;    
    background-color: rgb(77, 77, 77);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.show-note-container-title {
    width: 56%;
    text-align: right;
    color: white;
}

.show-note-container-close {
    color: white;
    font-size:2em;
    cursor: pointer;
    width: 10%;
}

.show-note-container-text{
    color: white;
}

.new-note-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.new-note-row:hover {
    background-color: rgb(58, 57, 57);
    cursor: pointer;
}

.project-view-files {
    width: 30em;
    height: 30em;
    background-color: rgb(24,24,24);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.new-file-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.new-file-container:hover {
    cursor: pointer;
    background-color: rgb(58, 57, 57);;
}

.new-file-date {
    color: white;
}

.new-file-delete {
    text-decoration: none;
    border:none;
    background: none;
    color: white;
    font-size: 1.6rem;
    cursor: pointer;
}

.project-content-top-right {
    display: flex;
    flex-direction: column;
    gap: 1em;
    
}

.project-view-deadlines {
    width: 30em;
    height: 10em;
    background-color: rgb(24,24,24);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.project-view-deadlines-title {
    background-color: rgb(77, 77, 77);
    width: 100%;
    text-align: center;
    margin-top: 0;
    padding-top: .5em;
    padding-bottom: .5em;

}

.project-view-deadlines-button {
    width: 10em;
    height: 3em;
    
}

.project-create-deadlines {
    width: 30em;
    height: 15em;
    background-color: rgb(24, 24, 24);
    color: white;
}

.project-create-new-deadline {
    text-align: center;
    background-color: rgb(77, 77, 77);
    margin-top: 0;
    padding-top: .5em;
    padding-bottom: .5em;
}

.project-create-deadline-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-create-deadlines-title {
    width: 50%;
    margin: .5em;
    background-color: rgb(63,63,63);
    border: none;
    color: white;
    font-size: 1rem;
}

.project-create-deadlines-date {
    width: 50%;
    margin: .5em;
    background-color: rgb(63,63,63);
    border: none;
    color: white;
    font-size: 1rem;    
}

.project-create-deadlines-button {
    width: 10em;
    height: 3em;
    margin: .5em;
}

.project-create-files {
    width: 30em;
    height: 10em;
    background-color: rgb(24, 24, 24);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-create-files-title {
    text-align: center;
    color: white;
    background-color: rgb(77, 77, 77);
    width: 100%;
    margin-top: 0;
    padding-top: .5em;
    padding-bottom: .5em;
}

.project-create-files-form {
    display: flex;
    width: 10em;
    height: 3em;
    background-color: aliceblue;
    justify-content: space-evenly;
}

.project-create-files-input {
    height: 3em;
    width: 10em;
    background-color: rebeccapurple;
    display: none;
}

.project-create-input-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10em;
    height: 3em;
    background-color: white;
}

.project-content-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.project-content-container {
    width: 60%;
    min-width: 30em;
    background-color: rgb(77, 77, 77);
    text-align: center;
}

.upload-notes-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(24, 24, 24);
    justify-content: space-evenly;
    align-items: center;
}

.upload-notes-title {
    color: white;
}

.upload-notes-title-input {
    width: 50%;
    margin-top: 1em;
    margin-bottom: 1em;
    background-color: rgb(63,63,63);
    border: none;
    font-size: 1rem;
    color:white;
}

.upload-notes-body {
    width: 90%;
    height: 20em;
    resize: none;
    margin-bottom: 1em;
    background-color: rgb(63,63,63);
    border-color: rgb(63,63,63);
    border: none;
    font-size: 1rem;
    color: white;
}

.upload-notes-button {
    width: 10em;
    height: 3em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
}

@media screen and (max-width: 900px) {
    .upload-notes-form {
        min-width: 10em;
    }
}

@media screen and (max-width: 750px) {
    .show-note-container {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    .project-view-notes {
        width: 90vw;
    }

    .new-note-row {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .note-items-title {
        margin-left: auto;
        margin-right: auto;
    }

    .new-note-date {
       display: none;
    }

    .project-view-files {
        width: 90vw;
    }

    .new-file-container {
        display: flex;
        justify-content: center;
    }

    .file-items-title {
        margin-left: auto;
        margin-right: auto;
    }

    .new-file-date {
        display: none;
    }

    .project-view-deadlines {
        width: 90vw;
    }

    .project-create-deadlines {
        width: 90vw;
    }

    .project-create-files {
        width: 90vw;
    }

    .project-content-container {
        width: 90vw;
        min-width: 0;
    }
}