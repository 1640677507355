.sliding-navbar {
    background-color: rgb(56, 56, 56);
    color: white;
    width: 0;
    height: 100%;
    position: fixed;
    display:flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
 
}
 
.sliding-title {
    height: 5%;
    display: flex;
    align-items: center;
}
 
.sliding-links {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 20%;
    width: 100%;
}

.sliding-navbar-links {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 33%;
    text-decoration: none;
    color: white;
}

.sliding-navbar-links:hover {
    background-color: rgb(78, 78, 78);
}