.home {
    background-color: rgb(255, 255, 255);
    background-image: url("../assets/testimage10.png");
    background-size:cover;
    display: flex;
    flex-direction: column;
    gap: 3em;
}

.description-sections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1.0rem;
}

.description-title {
    line-height: 3rem;
    color:white;
}

.sign-up-button-link {
    width: 30%;
}

#link-spacing {
    margin-bottom: 1em;
}

.sign-up-button {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    border-width: 1px;
    padding-top: 1em;
    padding-bottom: 1em;
    transition: all 0.1s ease-in-out;
}

.main-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color:white;
}

.home-img {
    max-width: 25em;
    margin-left: 5%;
    margin-right: 5%;
    min-width:10%;
}

.description-right {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    font-size: larger;
    background-color: rgb(43, 36, 60);
    text-align: center;
    border-radius: 16px;
}

.description-left {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    font-size: larger;
    background-color: rgb(43, 36, 60);
    text-align: center;
    border-radius: 16px;
}

.description-p {
    word-wrap: break-word;
    max-width: 25em;
    line-height: 2.5rem;
}

#main-content-id-2 {
    margin-bottom: 10%;
}

.top {

}

.bottom {
}

@media screen and (max-width: 1099px) {

    .description-right {
        margin-top: 2em;
    }

    .sign-up-button-link {
        margin-bottom: 1em;
    }

    .description-left {
        margin-bottom: 2em;
    }

}