* {

  box-sizing: border-box;

}

body {

}

.App {
}

/*@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");*/